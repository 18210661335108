<script>
import { compareVersions } from "compare-versions"
import ModalClose from "@/components/ModalClose.vue"

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms))

export default {
  name: "CheckUpdateModal",
  components: { ModalClose },

  data: () => ({
    isOpen: false,
    currentVersion: null,
    latestVersion: null,
    checking: false,
    interval: null,
  }),

  mounted() {
    this.fetchVersion()

    // refetch every 5 minutes
    this.interval = setInterval(() => {
      if (this.checking || this.isUpdateAvailable) return
      this.fetchVersion()
    }, 30000)

    // refetch when modal is opened
    this.$watch(
      () => this.isOpen,
      (isOpen) => {
        if (isOpen) {
          this.fetchVersion()
        }
      }
    )
  },

  beforeMount() {
    if (this.interval) {
      clearInterval(this.interval)
    }
  },

  methods: {
    forceReload() {
      window.localStorage.removeItem("masterdata.terminal")
      window.localStorage.removeItem("masterdata.settings")
      window.localStorage.removeItem("masterdata.items")
      window.localStorage.removeItem("masterdata.discounts")
      window.localStorage.removeItem("masterdata.paymenttypes")

      window.location.reload()
    },

    async fetchVersion() {
      const baseUrl = window.location.origin

      this.checking = true
      await sleep(250)

      fetch(`${baseUrl}/manifest.json?v=${Date.now()}`)
        .then((response) => response.json())
        .then((data) => {
          if (!this.currentVersion) {
            this.currentVersion = data?.__version ?? "1.0-dev"
          }
          this.latestVersion = data?.__version ?? "1.0dev"
        })
        .finally(() => {
          this.checking = false
        })
    },
  },

  computed: {
    isUpdateAvailable() {
      return (
        this.latestVersion &&
        this.currentVersion &&
        compareVersions(this.latestVersion, this.currentVersion) > 0
      )
    },
  },
}
</script>

<template>
  <a
    class="cursor-pointer text-white relative inline-flex"
    @click.prevent="isOpen = true"
  >
    <span
      v-if="isUpdateAvailable"
      class="absolute w-2 h-2 bg-green-400 rounded-full top-0 right-0"
    />

    <svg
      class="h-6 w-6"
      viewBox="0 0 20 20"
      fill="currentColor"
    >
      <path
        fill-rule="evenodd"
        d="M4 2a1 1 0 011 1v2.101a7.002 7.002 0 0111.601 2.566 1 1 0 11-1.885.666A5.002 5.002 0 005.999 7H9a1 1 0 010 2H4a1 1 0 01-1-1V3a1 1 0 011-1zm.008 9.057a1 1 0 011.276.61A5.002 5.002 0 0014.001 13H11a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0v-2.101a7.002 7.002 0 01-11.601-2.566 1 1 0 01.61-1.276z"
        clip-rule="evenodd"
      />
    </svg>
  </a>

  <teleport to="#mobile-modal">
    <div
      v-if="isOpen"
      class="fixed grid items-center justify-center z-30 inset-0 overflow-y-auto"
    >
      <!-- Overlay -->
      <div
        class="fixed transition-opacity inset-0 bg-gray-500 opacity-75"
        @click.prevent="isOpen = false"
      />

      <!-- Content -->
      <div
        id="modal"
        class="relative z-30 flex flex-col rounded-sm text-left overflow-hidden shadow-xl m-6 w-full modal-content"
        role="dialog"
        aria-modal="true"
        aria-labelledby=""
      >
        <modal-close @close="isOpen = false" />

        <div class="flex flex-col gap-4 justify-center items-center">
          <img
            class="w-20 h-auto"
            src="../assets/img/logos/sjef_logo-white.png"
            alt="SJEF"
          />

          <button
            v-if="!isUpdateAvailable"
            type="button"
            class="button button-blur rounded-md"
            :disabled="checking"
            @click.prevent="fetchVersion"
          >
            <span class="text-sm font-medium">
              {{ checking ? "Checking..." : "Check for updates" }}
            </span>
          </button>

          <template v-if="isUpdateAvailable">
            <p class="text-center text-lg font-semibold">Update Available</p>

            <p class="text-center text-sm text-gray-400">
              A new version (v{{ latestVersion }}) of the app is available.
            </p>

            <button
              type="button"
              class="button button-blur rounded-md"
              :disabled="checking"
              @click.prevent="forceReload"
            >
              <span class="text-sm font-medium">Reload to update</span>
            </button>
          </template>

          <p>
            Getting trouble?
            <a
              href="#"
              class="text-sjefgreen-200"
              @click.prevent="forceReload"
            >
              Force reload the app.
            </a>
          </p>

          <p class="text-center text-sm text-gray-400">
            &copy; {{ new Date().getFullYear() }} SJEF. Version:
            <small class="font-normal">v{{ currentVersion }}</small>
          </p>
        </div>
      </div>
    </div>
  </teleport>
</template>

<style scoped>
.modal-content {
  width: 32rem;
}
</style>
