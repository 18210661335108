<template>
    <div class="navbar bg-gray-900 flex justify-between items-center py-1 pr-2">
        <div class="flex items-center">
            <div class="w-1/3 h-10 flex justify-start items-center mr-4">
                <img class="w-20 p-2" src="../assets/img/logos/sjef_logo-white.png" alt="Sjef logo" />

        </div>

            <div class="text-white text-sm font-medium flex items-center nav-name">
                <div class="px-4">{{ location.name }}</div> <div class="px-4">{{ terminal.code }}</div> <div class="pl-4 flex relative" v-if="clerk.name"><div class="mr-8 clerk-icon-divider"> {{ clerk.name }} </div> <modal-clerk-close /></div>
            </div>
        </div>

        <div class="w-1/3 flex justify-end items-center space-x-10">

            <div class="text-xs leading-none">{{appVersion}}</div>

            <!-- transactions -->
            <modal-transactions />

            <!-- settings -->
            <modal-settings v-if="terminal.admin_mode == true" />

            <!-- reload -->
            <CheckUpdateModal />

            <!-- status -->
            <status />

            <!-- logout -->
            <modal-logout />
        </div>
    </div>
</template>

<script>
import Status from '../components/Status.vue';
import ModalClerkClose from '../components/ModalClerkClose.vue';
import ModalLogout from '../components/ModalLogout.vue';
import ModalSettings from '../components/ModalSettings.vue';
import ModalTransactions from '../components/ModalTransactions.vue';
import CheckUpdateModal from "@/components/CheckUpdateModal.vue"
import {version} from '../../package'

export default {
    name: 'navigation.pos',
    components: {
        CheckUpdateModal,
        Status,
        ModalClerkClose,
        ModalLogout,
        ModalSettings,
        ModalTransactions,
    },
    data() {
        return {
            mobile_open: false,
            appVersion: version
        }
    },
    methods: {
        reload() {
            localStorage.removeItem('masterdata.terminal');
            localStorage.removeItem('masterdata.settings');
            localStorage.removeItem('masterdata.items');
            localStorage.removeItem('masterdata.discounts');
            localStorage.removeItem('masterdata.paymenttypes');

            location.reload();
        },
    },
	computed: {
		location() {
			return this.$store.getters['location/location'];
        },
		terminal() {
			return this.$store.getters['terminal/terminal'];
        },
		clerk() {
			return this.$store.getters['transaction/clerk'];
        },
    }
}
</script>
